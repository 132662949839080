import React, { useState } from "react";
import { useRef } from "react";
import emailjs from "@emailjs/browser";

const Contact = () => {
  const [location, setLocation] = useState("https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3356.418416993852!2d-117.10834261266676!3d32.72807861864502!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x80d9538aa9ca7e61%3A0x142a5439dc36b72e!2s4255%20Juniper%20St%2C%20San%20Diego%2C%20CA%2092105!5e0!3m2!1sen!2sus!4v1712204759606!5m2!1sen!2sus");
  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();
    emailjs
      .sendForm(
        "service_084zvyf",
        "template_k3kmwke",
        form.current,
        "Ji8wrphn760cPgoCT"
      )
      .then(
        (result) => {
          console.log(result.text);
        },
        (error) => {
          console.log(error.text);
        }
      );
    e.target.reset();
  };

  return (
    <section className="text-gray-600 body-font relative">
      <div className="px-[4%] py-16 mx-auto flex sm:flex-nowrap flex-wrap">
        <div className="lg:w-2/3 md:w-1/3 rounded-lg overflow-hidden sm:mr-10 p-8 flex items-end justify-start relative">
          <iframe
            width="100%"
            height="100%"
            className="absolute inset-0"
            frameborder="0"
            title="map"
            marginheight="0"
            marginwidth="0"
            scrolling="no"
            src={location}
          ></iframe>
          <div className="bg-white relative flex flex-wrap py-16 rounded shadow-md">
            <div className="lg:w-1/2 px-5">
              <h2 className="title-font font-semibold text-gray-900 tracking-widest text-xs">
                ADDRESS
              </h2>
              <span>
                <p
                  className="mt-1 text-black font-bold hover:text-blue-600 hover:cursor-pointer"
                  onClick={() => {
                    setLocation("");
                  }}
                >
                 4255 juniper st
                </p>
                <p className="mt-1 text-gray-600">
                4255 juniper st
                </p>
              </span>
              <span>
               
              </span>
            </div>
            <div className="lg:w-1/2 px-6 mt-4 lg:mt-0">
              <h2 className="title-font font-semibold text-gray-900 tracking-widest text-xs">
                EMAIL
              </h2>
              <a
                href="mailto:afikirte@gmail.com"
                target="_blank"
                className="text-indigo-500 leading-relaxed"
              >
                Afikirte@gmail.com
              </a>
              <br />
              <a
                href="mailto:fikirdicore@gmail.com"
                target="_blank"
                className="text-indigo-500 leading-relaxed"
              >
                fikirdicore@gmail.com
              </a>
              <br />
              <h2 className="title-font font-semibold text-gray-900 tracking-widest text-xs mt-4">
                CALL FOR APPOINTMENT
              </h2>
              <p className="leading-relaxed">
                <a href="tel:17032832392" className="text-blue-600">
                  +1-213-294-9387
                </a>
              </p>
            </div>
          </div>
        </div>
        <form
          ref={form}
          onSubmit={sendEmail}
          className="lg:w-1/3 md:w-1/2 text-black flex flex-col md:ml-auto w-full md:py-16 mt-10 md:mt-2"
        >
          <h2 className="text-lg mb-1 font-medium title-font">Get In Touch!</h2>
          <p className="leading-relaxed mb-5 ">
            Use the contact form below to get in touch.
          </p>
          <div className="relative mb-4">
            <label for="name" className="leading-7 text-sm ">
              Name
            </label>
            <input
              type="text"
              id="from_name"
              name="from_name"
              className="w-full bg-white rounded border border-gray-300 focus:border-indigo-500 focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"
            />
          </div>
          <div className="relative mb-4">
            <label for="email" className="leading-7 text-sm ">
              Email
            </label>
            <input
              type="email"
              id="email_id"
              name="email_id"
              className="w-full bg-white rounded border border-gray-300 focus:border-indigo-500 focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"
            />
          </div>
          <div className="relative mb-4">
            <label for="message" className="leading-7 text-sm ">
              Message
            </label>
            <textarea
              id="message"
              name="message"
              className="w-full bg-white rounded border border-gray-300 focus:border-indigo-500 focus:ring-2 focus:ring-indigo-200 h-32 text-base outline-none text-gray-700 py-1 px-3 resize-none leading-6 transition-colors duration-200 ease-in-out"
            ></textarea>
          </div>
          <button className="text-white bg-indigo-500 border-0 py-2 px-6 focus:outline-none hover:bg-indigo-600 rounded text-lg">
            Send
          </button>
          <p className="text-xs text-justify mt-3">
            We would love to hear from you!
          </p>
        </form>
      </div>
    </section>
  );
};

export default Contact;
