import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Header from "./component/Header/Header";
import Footer from "./component/Footer/Footer";
import Home from "./component/Home/Home";
import NotFound from "./component/NotFound/NoteFound";
import Contact from "./component/Contact/Contact";
import Gallery from "./component/Gallery/Gallery";
import About from "./component/About/About";
import Admin from "./component/Admin/Admin"; // Import the Admin component
import './tailwind.css';
import "./App.css";

import { DataContextProvider } from './component/DataContext/DataContext';

function App() {
  return (
    <Router basename="/"> {/* Set basename to "/" for fikirdicore.com */}
      <DataContextProvider>
        <div className="App">
          <Header />
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/gallery" element={<Gallery />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/about" element={<About />} />
            <Route path="/admin" element={<Admin />} />
            <Route path="*" element={<NotFound />} />
          </Routes>
          <Footer />
        </div>
      </DataContextProvider>
    </Router>
  );
}

export default App;
