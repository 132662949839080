// DataContext.js
import React, { createContext, useState, useEffect } from 'react';

const DataContext = createContext();

const DataContextProvider = ({ children }) => {
  const [data, setData] = useState(null);

  // Load data from local storage on component mount
  useEffect(() => {
    const storedData = localStorage.getItem('storedData');
    if (storedData) {
      setData(JSON.parse(storedData));
    }
  }, []);

  // Save data to local storage whenever it changes
  useEffect(() => {
    localStorage.setItem('storedData', JSON.stringify(data));
  }, [data]);

  return (
    <DataContext.Provider value={{ data, setData }}>
      {children}
    </DataContext.Provider>
  );
};

export { DataContext, DataContextProvider };
