import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Modal } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

function GalleryDetail({ imagePath }) {
  const [open, setOpen] = useState(false);
  const [imageDimensions, setImageDimensions] = useState({ width: 0, height: 0 });
  const imageRef = useRef(null);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  useEffect(() => {
    // Measure the natural width and height of the image when it's loaded
    const handleImageLoad = () => {
      if (imageRef.current) {
        const { naturalWidth, naturalHeight } = imageRef.current;
        setImageDimensions({ width: naturalWidth, height: naturalHeight });
      }
    };

    if (imageRef.current && imageDimensions.width === 0) {
      // If the dimensions are not yet set, add event listener for image load
      imageRef.current.addEventListener('load', handleImageLoad);
    }

    // Cleanup the event listener on component unmount
    return () => {
      if (imageRef.current) {
        imageRef.current.removeEventListener('load', handleImageLoad);
      }
    };
  }, [imageDimensions]);

  return (
    <>
      <div className="h-72 md:h-[32rem] lg:h-[40rem] lg:w-1/3 w-full md:w-1/2 p-4">
        <div className="flex h-full w-full relative" onClick={handleOpen}>
          <img
            ref={imageRef}
            alt="gallery"
            className="w-full h-full rounded-lg object-fill cursor-pointer"
            src={imagePath}
          />
        </div>

        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-title"
          aria-describedby="modal-description"
          className="flex items-center justify-center"
        >
          <div className="max-w-screen-lg max-h-screen-lg overflow-auto bg-white p-4">
            <img
              alt="gallery"
              className="w-full rounded-lg"
              src={imagePath}
              style={{
                maxWidth: '100%',
                maxHeight: 'calc(100vh - 100px)', // Adjust as per your requirement
              }}
            />
            <button
              className="absolute top-4 right-4 bg-white p-2 rounded-full hover:bg-gray-100 focus:outline-none"
              onClick={handleClose}
              aria-label="Close"
            >
              <CloseIcon />
            </button>
          </div>
        </Modal>
      </div>
    </>
  );
}

GalleryDetail.propTypes = {
  imagePath: PropTypes.string.isRequired,
};

export default GalleryDetail;
