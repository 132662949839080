import React, { useState } from 'react';
import img1 from "../../assets/image/aa.jpg"
import img2 from "../../assets/image/WhatsApp Image 2023-09-19 at 22.06.11.jpg"
import img3 from "../../assets/image/fikir3.jpg"

function Content() {
  const [selectedImageIndex, setSelectedImageIndex] = useState(null);

  const handleClick = (index) => {
    setSelectedImageIndex(index === selectedImageIndex ? null : index);
  };

  return (
    <section className="text-gray-600 body-font">
      <div className="container px-5 py-24 mx-auto">
        <div className="flex flex-wrap w-full mb-20">
          <div className="lg:w-1/3 w-full mb-6 lg:mb-0">
            <h1 className="sm:text-3xl text-2xl font-medium title-font mb-2 text-gray-900">Meet Our Best Service </h1>
            <div className="h-1 w-20 bg-indigo-500 rounded"></div>
          </div>
          <p className="lg:w-1/2 w-full leading-relaxed text-gray-500">Discover Fikir Decore's exceptional Santiago decorations, meticulously crafted to elevate any occasion. Whether it's a wedding, graduation, birthday, or special event in Santiago or beyond, our team specializes in creating unforgettable atmospheres. With a keen eye for detail, creative flair, and a commitment to excellence, we transform your vision into reality, ensuring a stress-free experience and crafting beautiful memories together. 
          Trust Fikir Decore to make your event extraordinary with our passion for design and personalized service, making every moment truly unforgettable.</p>
        </div>
        <div className="flex flex-wrap -m-3">
          {[
            { src: img1, alt: "Wedding Wonderland: Elegance Redefined", title: "Exclusive Decoration", description: "At Fikir Decore, we understand that your wedding day is a once-in-a-lifetime celebration of love, and we're here to make it extraordinary. Our Wedding Wonderland service is tailored to create a dreamy and enchanting atmosphere that reflects the unique essence of your love story. Picture a venue adorned with timeless elegance, intricate floral arrangements, and personalized touches that make your special day truly unforgettable. Let us weave magic into every detail, ensuring that your wedding is a masterpiece that you and your guests will cherish forever." },
            { src: img2, alt: "Graduation Gala: Celebrate Success in Style", title: "Modest premium", description: "Graduating is a significant milestone, and it deserves a celebration as exceptional as your achievements. With Fikir Decore's Graduation Gala service, we bring sophistication and flair to your graduation party. Imagine a chic and stylish setting that perfectly complements your success. Whether you prefer a contemporary ambiance or a classic touch, our team will curate a design that mirrors your accomplishments and sets the stage for a night of joyous celebration. Trust us to turn your graduation into a glamorous affair that marks the beginning of a new chapter in style." },
            { src: img3, alt: "Whimsical Birthdays: Where Imagination Meets Celebration", title: "premium Mysteries", description: "Birthdays are a celebration of life, and at Fikir Decore, we specialize in turning your vision into a whimsical reality. Our Whimsical Birthdays service is designed to create a magical and joyful atmosphere for the young and young-at-heart. Imagine vibrant colors, playful themes, and personalized details that make your birthday celebration truly one-of-a-kind. Whether it's a first birthday or a milestone celebration, we bring creativity and innovation to the table, ensuring that your special day is filled with laughter, happiness, and delightful memories. Let us be the architects of your birthday dreams, where imagination meets celebration." }
          ].map((image, index) => (
            <div key={index} className="xl:w-1/3 md:w-1/2 p-4 relative">
              <div className="bg-gray-100 p-6 rounded-lg">
                <img
                  className="h-40 rounded w-full object-cover object-center mb-6 cursor-pointer"
                  src={image.src}
                  alt={image.alt}
                  onClick={() => handleClick(index)}
                />
                <h3 className="tracking-widest text-indigo-500 text-xs font-medium title-font">{image.alt}</h3>
                <h2 className="text-lg text-gray-900 font-medium title-font mb-4">{image.title}</h2>
                <p className="leading-relaxed text-base">{image.description}</p>
              </div>
              {selectedImageIndex === index && (
                <div className="absolute inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
                  <img
                    className="max-w-full max-h-full"
                    src={image.src}
                    alt={image.alt}
                    onClick={() => handleClick(index)}
                  />
                </div>
              )}
            </div>
          ))}
        </div>
      </div>
    </section>
  )
}

export default Content;
