import React, { useState, useEffect } from "react";
import ResponsiveImage from "react-responsive-image";

function Banner() {
  // State variables
  const [currentIndex, setIndex] = useState(0);
  const [image, setImage] = useState("");
  const [images, setImages] = useState([]);

  // Function to import images
  const importAll = (r) => {
    return r.keys().map(r);
  };

  // Effect to set initial images
  useEffect(() => {
    const imagePaths = importAll(
      require.context("../../assets/hero", false, /\.(png|jpe?g|svg)$/)
    );
    setImages(imagePaths);
    setImage(imagePaths[currentIndex]);

    // Start automatic rotation
    const interval = setInterval(() => {
      setIndex((prevIndex) => (prevIndex + 1) % images.length);
    }, 5000); // Change the interval as needed (5000 milliseconds = 5 seconds)

    // Clean up the interval on component unmount
    return () => clearInterval(interval);
  }, [currentIndex, images.length]);

  // Event handler for selecting image
  const handleImageSelect = (index) => {
    setIndex(index);
  };

  // Event handler for previous button click
  const handlePrevClick = () => {
    const newIndex = currentIndex > 0 ? currentIndex - 1 : images.length - 1;
    setIndex(newIndex);
  };

  // Event handler for next button click
  const handleNextClick = () => {
    const newIndex = currentIndex + 1 < images.length ? currentIndex + 1 : 0;
    setIndex(newIndex);
  };

  return (
    <div className="relative w-full max-w-4xl mx-auto">
      {/* Banner Image */}
      <div className="overflow-hidden relative rounded-lg shadow-lg">
        <img
          src={image}
          className="w-full rounded-lg"
          alt="Banner"
          style={{ maxHeight: "500px" }} // Adjust the max height as needed
        />
      </div>

      {/* Circular Indicators */}
      <div className="absolute bottom-0 left-0 right-0 flex justify-center mb-4">
        {images.map((img, index) => (
          <img
            key={index}
            src={img}
            className={`w-6 h-6 mx-2 rounded-full cursor-pointer ${
              index === currentIndex ? "border-blue-500 border-2" : "border-gray-300"
            } hover:border-blue-500 transition duration-300`}
            onClick={() => handleImageSelect(index)}
          />
        ))}
      </div>

      {/* Navigation Arrows */}
      <button
        className="absolute left-0 top-1/2 transform -translate-y-1/2 px-4 py-2 bg-gray-800 bg-opacity-50 text-white rounded-full focus:outline-none"
        onClick={handlePrevClick}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="h-6 w-6"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={2}
            d="M15 19l-7-7 7-7"
          />
        </svg>
      </button>
      <button
        className="absolute right-0 top-1/2 transform -translate-y-1/2 px-4 py-2 bg-gray-800 bg-opacity-50 text-white rounded-full focus:outline-none"
        onClick={handleNextClick}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="h-6 w-6"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={2}
            d="M9 5l7 7-7 7"
          />
        </svg>
      </button>
    </div>
  );
}

export default Banner;
