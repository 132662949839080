import React, { useState, useEffect } from "react";
import { TablePagination } from "@material-ui/core";
import GalleryDetail from "./GalleryDetails";
import CloseIcon from '@mui/icons-material/Close';

function Gallery() {
  const [images, setImages] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [loading, setLoading] = useState(true); // Add loading state

  useEffect(() => {
    const importAll = (r) => {
      try {
        const imagePaths = r.keys().map(r);
        setImages(imagePaths);
      } catch (error) {
        console.error("Error loading images:", error);
        setImages([]);
      } finally {
        setLoading(false);
      }
    };

    importAll(
      require.context("../../assets/GalleryO", false, /\.(png|jpe?g|svg)$/)
    );
  }, []);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <section className="text-black body-font">
      <div className="container px-5 py-24 mx-auto">
        <div className="flex flex-col text-center w-full mb-20">
          <h1 className="sm:text-3xl text-2xl font-medium title-font mb-4 text-black">
            Capturing Extraordinary Moments with Fikir Decore
          </h1>
          <p className="lg:w-2/3 mx-auto leading-relaxed text-base">
            Welcome to our Gallery, where moments come to life and memories
            unfold in vibrant colors and exquisite details. Explore a visual
            journey through our diverse portfolio, showcasing the magic we've
            crafted for weddings, graduations, birthdays, and more. Each image
            tells a unique story of love, success, and celebration. Let these
            snapshots inspire your imagination and envision the possibilities
            for your next extraordinary event with Fikir Decore.
          </p>
        </div>

        {loading ? (
          <div>Loading...</div> // Add loading indicator
        ) : (
          <>
            <div className="flex flex-wrap -m-4">
              {images
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((imagePath, index) => (
                  <GalleryDetail imagePath={imagePath} key={index} />
                ))}
            </div>
            <TablePagination
              component="div"
              count={images.length}
              page={page}
              onPageChange={handleChangePage}
              rowsPerPage={rowsPerPage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </>
        )}
      </div>
    </section>
  );
}

export default Gallery;
